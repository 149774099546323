import { navigate } from "gatsby";
import React, { useEffect } from "react";
import { Helmet } from 'react-helmet';

const IndexPage = () => {

  useEffect(() => {
    navigate('/knowledge-base')
  }, [])

  return (
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
  )
}
export default IndexPage